<template>
  <div>
    <div v-if="accountsBilling.length > 1 || accountsPrePay.length > 1">
      <h2>Your Accounts</h2>
    </div>

    <div v-if="accountsBilling.length > 1">
      <img
        alt="Credit Billing"
        class="account-icon"
        src="../../../assets/icon-credit-billing.png"
      />
      <h3>Credit Billing Accounts</h3>

      <div>
        <b-form-select
          @change="changeDropdownBilling"
          class="account-dd"
          v-model="selectedBilling"
          :options="accountsBilling"
        ></b-form-select>
      </div>
    </div>

    <div v-if="accountsPrePay.length > 1">
      <img
        alt="PrePayemnt"
        class="account-icon"
        src="../../../assets/icon-prepay.png"
      />
      <h3>PrePayment Accounts</h3>

      <div>
        <b-form-select
          @change="changeDropdownPrePay"
          class="account-dd"
          v-model="selectedPrePay"
          :options="accountsPrePay"
        ></b-form-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountsList",
  data() {
    return {
      billingTotal: 0,
      prepayTotal: 0,
      selectedBilling: null,
      selectedPrePay: null
    };
  },
  computed: {
    accounts() {
      return this.$store.state.auth.accounts;
    },
    accountsBilling() {
      const accounts = this.accounts.filter(function(u) {
        return u.type === "billing";
      });

      return this.formatAccount(accounts);
    },
    accountsPrePay() {
      const accounts = this.accounts.filter(function(u) {
        return u.type === "prepay";
      });

      return this.formatAccount(accounts);
    }
  },
  methods: {
    formatAccount(accounts) {
      let items = {};

      accounts.forEach(row => {
        if (!items.hasOwnProperty(row.group)) {
          items[row.group] = [];
        }
        items[row.group].push(row);
      });

      let final = [
        { value: null, text: "Please select an apartment", disabled: true }
      ];

      const keys = Object.keys(items).sort();

      keys.forEach(key => {
        let newItem = {
          label: key,
          options: []
        };
        items[key].forEach(item => {
          newItem.options.push({
            value: item.reference,
            text: item.name + " (" + item.paypoint + ")"
          });
        });
        final.push(newItem);
      });

      return final;
    },
    changeDropdownBilling() {
      this.$router.push("/billing/" + this.selectedBilling);
    },
    changeDropdownPrePay() {
      this.$router.push("/prepay/" + this.selectedPrePay);
    }
  }
};
</script>

<style lang="scss" scoped>
.account-select {
  padding: 30px 0px;

  .icon {
    font-size: 3rem;
    color: $dePurple;
  }

  h2 {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
  }

  h3 {
    margin-top: 15px;
    font-size: 1.3rem;
    color: #666;
  }

  h5 {
    font-size: 1.1rem;
    margin-top: 20px;
    font-weight: bold;
  }

  p {
    font-size: 0.9rem;
  }
}

.account-dd {
  margin-top: 15px;
  margin-bottom: 25px;
  width: auto;
  max-width: 95%;
}

.account-icon {
  width: 70px;
  margin-top: 20px;
}
</style>
